<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Promo</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Promo</h2>
						</div>
						<div class="text-center my-3" v-if="isRefresh">
							<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
						</div>
						<div v-else>
							<div v-if="total_promo > 0">
								<div class="row max-content-user customScroolBar" style="max-height: 250vh;">
									<div class="col-lg-4 col-md-6 col-6 p-2" v-for="(promo, index) in list_promo" :key="index">
										<div class="card product round overflow-hidden">
											<router-link :to="'/product-list?type=promo&q='+promo.promo_title_slug.toLowerCase()" class="text-decoration-none">
												<div class="d-flex align-items-center" style="height: 118px; overflow: hidden;">
													<img v-lazy="imagePromo(promo.promo_image)" @error="imageLoadError" class="card-img-top" :alt="promo.promo_title_slug" />
												</div>
											</router-link>
											<div class="card-body border-top p-3 product-card text-muted">
												<router-link :to="'/product-list?type=promo&q='+promo.promo_title_slug.toLowerCase()" class="text-decoration-none">
													<h5 class="text-dark" style="height: 48px; overflow: hidden;">{{ promo.promo_name | str_limit(40) }}</h5>
												</router-link>
												<p class="p-0">Berlaku Hingga : {{ promo.promo_end_date | momentFormatID }}</p>
											</div>
											<div class="card-footer d-inline-flex w-100 px-1 py-2 d-flex justify-content-center">
												<router-link :to="'/product-list?type=promo&q='+promo.promo_title_slug.toLowerCase()" class="text-decoration-none">
													<button class="btn btn-outline-primary btn-sm w-100 m-1 px-1"><i class="fa fa-search-plus"></i> Lihat Produk Promo</button>
												</router-link>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12 w-100 text-center mt-1">
										<hr />
										<b-pagination v-model="current_page" :per-page="per_page" @change="get_promo" :total-rows="total_promo" first-number last-number align="center" size="md"></b-pagination>
									</div>
								</div>
							</div>
							<div v-else>
								<div class="text-center my-2">
									<h4 class="text-info mb-1">Oops!</h4>
									<p class="text-muted">Tidak ada Promo hari ini.</p>
									<p class="text-muted">Coba kembali lagi besok!</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	data() {
		return {
			list_promo: [],
			current_page: 1,
			per_page: 0,
			total_promo: 0,
			isRefresh: false,
		}
	},
	methods: {
		get_promo(current_page) {
			this.isRefresh = true
			window.axios.get('/promo?page='+ current_page)
 			.then((response) => {
				this.per_page = response.data.data.per_page
				this.total_promo = response.data.data.total
				this.current_page = current_page
				this.list_promo = response.data.data.data

				this.isRefresh = false
				let position = $(document).scrollTop()
				$('html, body').stop().animate({
					scrollTop: 1
				}, 1000, 'easeInOutExpo')
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
	},
	created() {
		$(document).scrollTop(0)
		this.get_promo(1)
	},
}
</script>